import React from "react";
import { useNavigate } from "react-router-dom";

import { HeaderContainer } from "./styles";

import Logo from "../../Assets/SeuGift.png";
import iconMenu from "../../Assets/menu(white).svg";
import WhatsApp from "../../Assets/whatsapp.png";

import { ENDERECO_GOOGLEMAPS, WHATSAPP_SEUGIFT } from "../../Helper/link";

import useUser from "../../Context/User/useUser";

export const Header = () => {
  const navigate = useNavigate();

  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  const { loginUser, userLogout } = useUser();

  return (
    <HeaderContainer>
      <div>
        <div>
          <img
            width={50}
            src={Logo}
            alt="SeuGift Logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <a
            href="/"
            rel="noreferrer"
            target="_parent"
            title="Voltar para a página inicial"
          >
            SeuGift
          </a>
        </div>
        <div>
          <img width={50} src={iconMenu} alt="Menu" onClick={toggleMenu} />
        </div>
      </div>
      <div>
        <p>Fortalecendo Relacionamentos</p>
        <div>
          <a href={WHATSAPP_SEUGIFT} target="_blank" rel="noreferrer">
            Pedidos (19 98767-8840)
            <img src={WhatsApp} alt="WhatsApp Icon" height={20} />
          </a>
          <a target="_blank" rel="noreferrer" href={ENDERECO_GOOGLEMAPS}>
            Loja - Rua Almirante Tamandaré, 862 - Indaiatuba - SP
          </a>
        </div>
      </div>
      {menuVisible && (
        <div className="dropdown-menu active">
          <p
            onClick={() => {
              setMenuVisible(false);
              navigate("/autenticação");
            }}
          >
            Autenticação
          </p>
          {loginUser ? (
            <p
              onClick={() => {
                userLogout();
                setMenuVisible(false);
                navigate("/autenticação");
              }}
            >
              Logout
            </p>
          ) : null}
        </div>
      )}
    </HeaderContainer>
  );
};
