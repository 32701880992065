import React from "react";

import { ImageSubContainer, ListContainer, SubListContainer } from "./styles";

import iconDelete from "../../../../../Assets/delete(black).svg";
import iconBasket from "../../../../../Assets/shopping-basket(black).svg";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";
import Select from "../../../../../Components/Select";
import Textarea from "../../../../../Components/Textarea";
import ImageUpload from "../../../../../Components/ImageUpload";

import { CREATE_PRODUCT } from "../../../../../Api/Produto";

import useForm from "../../../../../Hook/useForm";

const ModalCreate = ({
  isOpen,
  setIsOpen,
  items,
  categories,
  handleListProducts,
}) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const owner = useForm(true);
  const name = useForm(true);
  const price = useForm("price");
  const description = useForm(false);

  const [currentStock, setCurrentStock] = React.useState(1);
  const [minimumStock, setMinimumStock] = React.useState(1);

  const [mainImage, setMainImage] = React.useState(null);
  const [mainImagePreview, setMainImagePreview] = React.useState(iconBasket);

  const [categoryId, setCategoryId] = React.useState(null);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const [itemId, setItemId] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const [secondaryImages, setSecondaryImages] = React.useState([]);
  const [newSecondaryImage, setNewSecondaryImage] = React.useState(null);
  const [newSecondaryImagePreview, setNewSecondaryImagePreview] =
    React.useState(iconBasket);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    owner.setValue("");
    name.setValue("");
    price.setValue("");
    description.setValue("");
    setCurrentStock(1);
    setMinimumStock(1);
    setMainImage("");
    setMainImagePreview(iconBasket);
    setCategoryId(null);
    setSelectedCategories([]);
    setItemId(null);
    setSelectedItems([]);
    setSecondaryImages([]);
    setNewSecondaryImage(null);
    setNewSecondaryImagePreview(iconBasket);
  };

  const handleAddCategory = () => {
    const category = categories.find(
      (i) => JSON.stringify(i.id) === categoryId
    );

    if (!category) return;

    const categoryExists = selectedCategories.some(
      (selectedItem) => selectedItem.id === category.id
    );

    if (categoryExists) return;

    setSelectedCategories((prevCategories) => [
      ...prevCategories,
      { id: category.id, nome: category.nome },
    ]);

    setCategoryId(null);
  };

  const handleRemoveCategory = (id) => {
    setSelectedCategories((prevItems) =>
      prevItems.filter((item) => item.id !== id)
    );
  };

  const handleAddItem = () => {
    const item = items.find((i) => JSON.stringify(i.id) === itemId);

    if (!item) return;

    const itemExists = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (itemExists) return;

    setSelectedItems((prevItems) => [
      ...prevItems,
      { id: item.id, nome: item.nome },
    ]);

    setItemId(null);
  };

  const handleRemoveItem = (id) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleAddImage = () => {
    if (newSecondaryImage) {
      setSecondaryImages((prevImages) => [...prevImages, newSecondaryImage]);
      setNewSecondaryImage(null);
      setNewSecondaryImagePreview(iconBasket);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    setSecondaryImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCreateProduct = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        owner.validation() &&
        name.validation() &&
        price.validation() &&
        currentStock > 0 &&
        minimumStock > 0
      ) {
        if (itemId) {
          setError("Adicione o item selecionado");
          return;
        }

        if (categoryId) {
          setError("Adicione a categoria selecionada");
          return;
        }

        if (mainImage === null) {
          setError("Escolha a Imagem Principal");
          return;
        }

        if (selectedCategories.length < 1) {
          setError("Adicione pelo menos uma categoria");
          return;
        }

        const token = window.localStorage.getItem("token");

        const formData = new FormData();

        formData.append("nome", name.value);
        formData.append("preco", price.value.replace(",", "."));
        formData.append("descricao", description.value);
        formData.append("criador", owner.value);
        formData.append("estoque_atual", currentStock);
        formData.append("estoque_minimo", minimumStock);
        formData.append("itens", JSON.stringify(selectedItems));
        formData.append("categorias", JSON.stringify(selectedCategories));

        formData.append("imagens", mainImage);
        secondaryImages.forEach((img) => {
          formData.append("imagens", img);
        });

        const { url, options } = CREATE_PRODUCT(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await handleListProducts();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (err) {
      setError("Falha no cadastro. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleCreateProduct}>
      <h1>Cadastro de Produto</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Dono do Produto"
        {...owner}
        label="Proprietário"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome do Produto"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Preço do Produto"
        {...price}
        label="Preço"
      />
      <Textarea
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <ListContainer>
        <h1>Categorias</h1>
        <SubListContainer>
          <h1>Adicionar Categorias</h1>
          <Select
            disabled={loading}
            title="Categoria do Produto"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            <option value="">Selecione uma categoria</option>
            {categories.map((category) => (
              <option key={category?.id} value={category?.id}>
                {category?.nome}
              </option>
            ))}
          </Select>
          <Button type="button" disabled={loading} onClick={handleAddCategory}>
            Adicionar Categoria
          </Button>
        </SubListContainer>
        <ul>
          {selectedCategories.length > 0 ? (
            selectedCategories.map((category) => (
              <li
                key={category?.id}
                onClick={() => handleRemoveCategory(category?.id)}
              >
                {category?.nome}{" "}
                <img width={25} src={iconDelete} alt="Icone de Delete" />
              </li>
            ))
          ) : (
            <p>Nenhum item selecionado</p>
          )}
        </ul>
      </ListContainer>
      <ListContainer>
        <h1>Itens</h1>
        <SubListContainer>
          <h1>Adicionar Itens</h1>
          <Select
            disabled={loading}
            title="Item do Produto"
            value={itemId}
            onChange={(e) => setItemId(e.target.value)}
          >
            <option value="">Selecione um item</option>
            {items.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.nome}
              </option>
            ))}
          </Select>
          <Button type="button" disabled={loading} onClick={handleAddItem}>
            Adicionar Item
          </Button>
        </SubListContainer>
        <ul>
          {selectedItems.length > 0 ? (
            selectedItems.map((item) => (
              <li key={item?.id} onClick={() => handleRemoveItem(item?.id)}>
                {item?.nome}{" "}
                <img width={25} src={iconDelete} alt="Icone de Delete" />
              </li>
            ))
          ) : (
            <p>Nenhum item selecionado</p>
          )}
        </ul>
      </ListContainer>
      <h2>Imagem Principal (Obrigatório)</h2>
      <ImageUpload
        label="Escolha uma imagem"
        loading={loading}
        setImage={setMainImage}
        imagePreview={mainImagePreview}
        setImagePreview={setMainImagePreview}
        onChange={() => {}}
      />
      <h2>Imagens Secundárias (Opcional)</h2>
      <ImageUpload
        label="Escolha uma imagem e adicione"
        loading={loading}
        setImage={setNewSecondaryImage}
        imagePreview={newSecondaryImagePreview}
        setImagePreview={setNewSecondaryImagePreview}
        onChange={() => {}}
      />
      <Button type="button" disabled={loading} onClick={handleAddImage}>
        Adicionar Imagem
      </Button>
      <ImageSubContainer>
        <h3>Imagens Secundárias</h3>
        {secondaryImages.length > 0 ? (
          secondaryImages.map((img, index) => {
            const imgURL = URL.createObjectURL(img);
            return (
              <div key={index}>
                <img key={index} src={imgURL} alt={`Imagem ${index + 1}`} />
                <Button
                  type="button"
                  disabled={loading}
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImage(index)}
                >
                  -
                </Button>
              </div>
            );
          })
        ) : (
          <h4>Nenhuma imagem selecionada</h4>
        )}
      </ImageSubContainer>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
    </Modal>
  );
};

export default ModalCreate;
