import styled from "styled-components";

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;

  max-width: 1000px;
  width: 100%;

  max-height: 600px;
  height: 100%;
`;

export const ThumbnailContainer = styled.div`
  max-width: 100px;
  width: 100%;
  height: auto;
  padding: 10px 0px;
  overflow-y: auto;
`;

export const ThumbnailImage = styled.img`
  max-width: 100px;
  width: 100%;
  max-height: 100px;
  height: 100%;

  object-fit: cover;

  cursor: pointer;

  margin-bottom: 10px;

  border-radius: 4px;
  border: 2px solid
    ${(props) => (props.selected ? props.theme.colors.color_03 : "transparent")};
`;

const shouldForwardProp = (prop) => !["zoomX", "zoomY"].includes(prop);

export const MainImageContainer = styled.div.withConfig({
  shouldForwardProp,
})`
  width: 100%;
  height: 100%;

  position: relative;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.background_03};

  &::after {
    width: 100%;
    height: 100%;

    display: ${(props) => props.display || "none"};
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    background-size: 200%;
    background-color: black;
    background-image: url(${(props) => props.url});
    background-position: ${(props) => `${props.zoomX}% ${props.zoomY}%`};
  }
`;

export const MainImage = styled.img`
  object-fit: contain;

  width: 100%;
  height: 100%;

  margin: 0px auto;
  border-radius: 20px;
`;
