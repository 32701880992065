import { API_URL } from "..";

const API_URL_PRODUCT = API_URL + "produto-imagens";

export function CREATE_PRODUCT_IMAGE(formData, token) {
  return {
    url: `${API_URL_PRODUCT}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function DELETE_PRODUCT_IMAGE(imageId, token) {
  return {
    url: `${API_URL_PRODUCT}/excluir?imagem_id=${imageId}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
