import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 9;
`;

export const ModalContainer = styled.form`
  max-width: 600px;
  min-width: 280px;
  width: 100%;

  max-height: 80vh;
  height: fit-content;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 0.625rem;

  position: relative;
  padding: 1.25rem;

  background-color: ${({ theme }) => theme.colors.background_02};

  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  > * {
    width: 100%;
  }
`;
