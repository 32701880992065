import styled from "styled-components";

export const TableProuctsContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;

  overflow: hidden;

  > div:nth-child(1) {
    width: 100%;
    height: fit-content;

    padding: 5px 12.5px;

    display: flex;
    align-items: center;
    justify-content: start;

    gap: 5px;

    background-color: ${({ theme }) => theme.colors.color_01};

    input {
      width: 33%;
      height: 40px;

      padding: 5px 10px;

      font-size: 1.125rem;
    }

    select {
      width: 33%;
      height: 40px;
    }

    button {
      width: 33%;
      height: 40px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.color_02};

      background-color: ${({ theme }) => theme.colors.color_02};

      &:hover {
        background-color: ${({ theme }) => theme.colors.color_03};
      }
    }
  }
`;
