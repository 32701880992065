import styled from "styled-components";

export const CatalogContainer = styled.div``;

export const DetailFilterContainer = styled.div`
  width: 100%;
  height: 40px;

  padding: 0px 5px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 5px;

  color: ${({ theme }) => theme.colors.text_01};
  background-color: ${({ theme }) => theme.colors.background_02};

  z-index: 1;

  cursor: pointer;
  user-select: none;
  overflow-y: hidden;

  strong {
    opacity: 1;

    color: ${({ theme }) => theme.colors.color_02};
    &:hover {
      color: ${({ theme }) => theme.colors.color_03};
    }
  }

  p {
    opacity: 0.5;
  }
`;

export const CatalogSubContainer = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto 1fr;
`;

export const ListCardProductContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px 5px;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(0, 280px));

  align-items: start;
  justify-content: center;

  gap: 10px;

  strong {
    width: 150px;
    position: fixed;
    top: 50%;
    left: calc((50% - (200px / 2)) + 50px);
    text-align: center;
  }

  > div {
    flex: 1;
  }
`;

export const ADModalTitle = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
`;

export const ADModalDescription = styled.p`
  padding: 5px;

  font-weight: normal;
  font-size: 1.125rem;

  text-align: center;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background_03};
`;
