import { API_URL } from "..";

const API_URL_ANUNCIO = API_URL + "anuncio-imagens";

export function CREATE_ANUNCIO_IMAGE(formData, token) {
  return {
    url: `${API_URL_ANUNCIO}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function DELETE_ANUNCIO_IMAGE(imageId, token) {
  return {
    url: `${API_URL_ANUNCIO}/excluir?imagem_id=${imageId}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
