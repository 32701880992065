import React, { useState, useEffect } from "react";
import Alert from "./Components/Alert";

import { ShowAlertContainer } from "./styles";

import useAlert from "../../Context/Alert/useAlert";

export const ShowAlert = () => {
  const [alertList, setAlertList] = useState([]);
  const { type, setType, message, setMessage } = useAlert();

  useEffect(() => {
    if (type && message) {
      const newAlert = { id: Date.now(), type, message };
      setAlertList((prevList) => [...prevList, newAlert]);

      setType(null);
      setMessage(null);
    }
  }, [type, message, setType, setMessage]);

  useEffect(() => {
    if (alertList.length === 0) return;

    const timer = setTimeout(() => {
      setAlertList((prevList) => prevList.slice(1));
    }, 3000);

    return () => clearTimeout(timer);
  }, [alertList]);

  return (
    <ShowAlertContainer>
      {alertList.map((alert) => (
        <Alert key={alert.id} type={alert.type} message={alert.message} />
      ))}
    </ShowAlertContainer>
  );
};
