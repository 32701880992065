import React from "react";

import {
  GalleryContainer,
  MainImage,
  MainImageContainer,
  ThumbnailContainer,
  ThumbnailImage,
} from "./styles";

import { IMAGE_PRODUCT } from "../../../../Api/Produto";

const ProductImageGallery = ({ images }) => {
  const [display, setDisplay] = React.useState("none");
  const [zoomX, setZoomX] = React.useState("0%");
  const [zoomY, setZoomY] = React.useState("0%");

  const [selectedImage, setSelectedImage] = React.useState(
    images.length > 0 ? images[0]?.nome : ""
  );

  const getImageUrl = (imageName) => IMAGE_PRODUCT(imageName);

  React.useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]?.nome);
    }
  }, [images]);

  return (
    <GalleryContainer>
      <ThumbnailContainer>
        {images.map((image, index) => (
          <ThumbnailImage
            key={index}
            src={getImageUrl(image?.nome)}
            alt={`Thumbnail ${index}`}
            selected={image?.nome === selectedImage}
            onClick={() => setSelectedImage(image?.nome)}
          />
        ))}
      </ThumbnailContainer>
      <MainImageContainer
        url={getImageUrl(selectedImage)}
        display={display}
        zoomX={zoomX}
        zoomY={zoomY}
        onMouseMove={(event) => {
          setDisplay("block");
          const { offsetX, offsetY } = event.nativeEvent;
          const { offsetWidth, offsetHeight } = event.currentTarget;
          setZoomX((offsetX * 100) / offsetWidth);
          setZoomY((offsetY * 100) / offsetHeight);
        }}
        onMouseOut={() => setDisplay("none")}
      >
        {selectedImage && (
          <MainImage alt="Main imagem" src={getImageUrl(selectedImage)} />
        )}
      </MainImageContainer>
    </GalleryContainer>
  );
};

export default ProductImageGallery;
