import styled from "styled-components";

export const Loader = styled.div`
  position: fixed;

  top: 40%;
  left: calc(50% - (250px / 2));
`;

export const Gif = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 20px;
`;
