import React from "react";

import { Gif, Loader } from "./styles";

import loadingGif from "../../Assets/SeuGift(carregando).gif";

function Loading() {
  return (
    <Loader>
      <Gif src={loadingGif} alt="Loading..." />
    </Loader>
  );
}

export default Loading;
