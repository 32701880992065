import styled from "styled-components";

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;
  align-items: center;
`;

export const ImageSubContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: ${({ theme }) => theme.colors.border_03};

  div {
    padding: 5px 0px;

    display: flex;
    flex-direction: column;

    gap: 5px;

    img {
      width: fit-content;
      height: 180px;
      margin: 0 auto;
    }
  }
`;
