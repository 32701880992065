import React from "react";

import { ImageSubContainer } from "./styles";

import iconBasket from "../../../../../Assets/shopping-basket(black).svg";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";
import Textarea from "../../../../../Components/Textarea";
import ImageUpload from "../../../../../Components/ImageUpload";

import { CREATE_AD } from "../../../../../Api/Anuncio";

import useForm from "../../../../../Hook/useForm";

const ModalCreate = ({ isOpen, setIsOpen, handleListAds }) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const title = useForm(true);
  const description = useForm(true);
  const dateStart = useForm(true);
  const dateEnd = useForm(true);

  const [images, setImages] = React.useState([]);

  const [newImage, setNewImage] = React.useState(null);
  const [newImagePreview, setNewImagePreview] = React.useState(iconBasket);

  const handleAddImageToAd = () => {
    if (newImage) {
      setImages((prevImages) => [...prevImages, newImage]);
      setNewImage(null);
      setNewImagePreview(iconBasket);
    }
  };

  const handleRemoveImageToAd = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const onClose = () => {
    setIsOpen(false);
    setError("");
    title.setValue("");
    description.setValue("");
    dateStart.setValue("");
    dateEnd.setValue("");
    setImages([]);
    setNewImage(null);
    setNewImagePreview(iconBasket);
  };

  const handleCreateAd = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        title.validation() &&
        description.validation() &&
        dateStart.validation() &&
        dateEnd.validation()
      ) {
        const token = window.localStorage.getItem("token");

        const formData = new FormData();
        formData.append("titulo", title.value);
        formData.append("descricao", description.value);
        formData.append("data_inicio", dateStart.value);
        formData.append("data_termino", dateEnd.value);

        images.forEach((image) => {
          formData.append("imagens", image);
        });

        const { url, options } = CREATE_AD(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await handleListAds();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (error) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleCreateAd}>
      <h1>Cadastro de Anúncio</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Título"
        {...title}
        label="Título"
      />
      <Textarea
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <Input
        required
        type="date"
        disabled={loading}
        title="Data de Inicio"
        {...dateStart}
        label="Data de Inicio"
      />
      <Input
        required
        type="date"
        disabled={loading}
        title="Data de Termino"
        {...dateEnd}
        label="Dia de Termino"
      />
      <h1>Imagens</h1>
      <ImageUpload
        label="Escolha uma imagem e adicione"
        loading={loading}
        setImage={setNewImage}
        imagePreview={newImagePreview}
        setImagePreview={setNewImagePreview}
        onChange={() => {}}
      />
      <Button type="button" disabled={loading} onClick={handleAddImageToAd}>
        Adicionar Imagem
      </Button>
      <ImageSubContainer>
        <h3>Imagens Selecionadas</h3>
        {images.length > 0 ? (
          images.map((image, index) => {
            const imageURL = URL.createObjectURL(image);
            return (
              <div key={index}>
                <img key={index} src={imageURL} alt={`Imagem ${index + 1}`} />
                <Button
                  type="button"
                  disabled={loading}
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImageToAd(index)}
                >
                  -
                </Button>
              </div>
            );
          })
        ) : (
          <h4>Nenhuma imagem selecionada</h4>
        )}
      </ImageSubContainer>
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </Modal>
  );
};

export default ModalCreate;
