import React from "react";

import { ImageSubContainer } from "./styles";

import iconBasket from "../../../../../Assets/shopping-basket(black).svg";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";
import Textarea from "../../../../../Components/Textarea";
import ImageUpload from "../../../../../Components/ImageUpload";

import { IMAGE_AD, UPDATE_AD } from "../../../../../Api/Anuncio";
import {
  CREATE_ANUNCIO_IMAGE,
  DELETE_ANUNCIO_IMAGE,
} from "../../../../../Api/AnuncioImagens";

import useForm from "../../../../../Hook/useForm";

const ModalUpdate = ({
  isOpen,
  setIsOpen,
  adData,
  setAdData,
  handleFindAd,
  handleListAds,
}) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const title = useForm(true);
  const description = useForm(true);
  const dateStart = useForm(true);
  const dateEnd = useForm(true);

  const [images, setImages] = React.useState([]);

  const [newImage, setNewImage] = React.useState(null);
  const [newImagePreview, setNewImagePreview] = React.useState(iconBasket);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    setAdData(null);
    title.setValue("");
    description.setValue("");
    dateStart.setValue("");
    dateEnd.setValue("");
    setImages([]);
    setNewImage(null);
    setNewImagePreview(iconBasket);
  }, [
    setIsOpen,
    setError,
    setLoading,
    setAdData,
    title,
    description,
    dateStart,
    dateEnd,
    setImages,
    setNewImage,
    setNewImagePreview,
  ]);

  React.useEffect(() => {
    if (adData) {
      title.setValue(adData.titulo);
      description.setValue(adData.descricao);
      dateStart.setValue(adData.data_inicio.split("T")[0]);
      dateEnd.setValue(adData.data_termino.split("T")[0]);
      setImages(adData?.imagens);
    } else {
      onClose();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [adData]);

  const handleUpdateAd = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        adData.id &&
        title.validation() &&
        description.validation() &&
        dateStart.validation() &&
        dateEnd.validation()
      ) {
        const token = window.localStorage.getItem("token");

        const formData = new FormData();
        formData.append("titulo", title.value);
        formData.append("descricao", description.value);
        formData.append("data_inicio", dateStart.value);
        formData.append("data_termino", dateEnd.value);

        const body = {
          titulo: title.value,
          descricao: description.value,
          data_inicio: dateStart.value,
          data_termino: dateEnd.value,
        };

        const { url, options } = UPDATE_AD(adData.id, body, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await handleListAds();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (error) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddImageToAd = async () => {
    if (!adData?.id || !newImage) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const formData = new FormData();
      formData.append("imagem", newImage);
      formData.append("anuncio_id", adData?.id);

      const token = window.localStorage.getItem("token");
      const { url, options } = CREATE_ANUNCIO_IMAGE(formData, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setNewImagePreview(iconBasket);
        setNewImage(null);
        handleFindAd(adData?.id);
        handleListAds();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao associar a imagem ao anúncio. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImageToAd = async (imageId) => {
    if (!imageId) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir esta imagem?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");
      const { url, options } = DELETE_ANUNCIO_IMAGE(imageId, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        handleFindAd(adData?.id);
        handleListAds();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao desassociar a imagem ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleUpdateAd}>
      <h1>Atualização de Anúncio</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Título"
        {...title}
        label="Título"
      />
      <Textarea
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <Input
        required
        type="date"
        disabled={loading}
        title="Data de Inicio"
        {...dateStart}
        label="Data de Inicio"
      />
      <Input
        required
        type="date"
        disabled={loading}
        title="Data de Termino"
        {...dateEnd}
        label="Dia de Termino"
      />
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <h1>Imagens</h1>
      <ImageUpload
        label="Escolha uma imagem e adicione"
        loading={loading}
        setImage={setNewImage}
        imagePreview={newImagePreview}
        setImagePreview={setNewImagePreview}
        onChange={() => {}}
      />
      <Button type="button" disabled={loading} onClick={handleAddImageToAd}>
        Adicionar Imagem
      </Button>
      <ImageSubContainer>
        <h3>Imagens Cadastradas</h3>
        {images.length > 0 ? (
          images.map((image, index) => {
            return (
              <div key={index}>
                <img
                  key={index}
                  src={IMAGE_AD(image?.nome)}
                  alt={`Imagem ${index + 1}`}
                />
                <Button
                  type="button"
                  disabled={loading}
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImageToAd(image?.id)}
                >
                  -
                </Button>
              </div>
            );
          })
        ) : (
          <h4>Nenhuma imagem cadastrada</h4>
        )}
      </ImageSubContainer>
    </Modal>
  );
};

export default ModalUpdate;
