import React from "react";
import PropTypes from "prop-types";

import { AlertContainer } from "./styles";

import iconAlertSuccess from "../../../../Assets/alert-success(white).svg";
import iconAlertError from "../../../../Assets/alert-error(white).svg";
import iconAlertFail from "../../../../Assets/alert-fail(white).svg";

import iconAlertDefault from "../../../../Assets/alert-default(white).svg";

function Alert({ type, message }) {
  const colors = {
    success: "#006400",
    error: "#D2691E",
    fail: "#8B0000",
  };

  const color = colors[type] || "#fb6c8e";

  const images = {
    success: iconAlertSuccess,
    error: iconAlertError,
    fail: iconAlertFail,
  };

  const image = images[type] || iconAlertDefault;

  return (
    <AlertContainer color={color}>
      <img src={image} alt="Imagem de Alerta" />
      <p>{message}</p>
    </AlertContainer>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

export default Alert;
