import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonsContainer,
  CardProductContainer,
  DetailsProductContainer,
  MainImageContainer,
  NameProduct,
  NumberItemsProduct,
  PriceProduct,
} from "./styles";

import iconStar from "../../Assets/star(white).svg";
import iconAddShoppingCart from "../../Assets/add-shopping-cart(white).svg";

import Button from "../Button";

import { IMAGE_PRODUCT } from "../../Api/Produto";

import useShoppingCart from "../../Context/ShoppingCart/useShoppingCart";

const CardProduct = ({ product }) => {
  const navigate = useNavigate();

  const { addProductToCart } = useShoppingCart();

  const handleOpenDetailsProduct = () => {
    navigate(`/produto/${product?.id}`);
  };

  return (
    <CardProductContainer
      className={`${product?.categoria_destaque ? "destaque" : ""}`}
    >
      <img
        width={20}
        src={iconStar}
        alt="Icone de Estrela"
        style={product?.categoria_destaque ? {} : { display: "none" }}
      />
      <MainImageContainer onClick={handleOpenDetailsProduct}>
        <img
          alt="Imagem do Produto"
          src={IMAGE_PRODUCT(product?.imagem_produto)}
        />
      </MainImageContainer>
      <DetailsProductContainer>
        <NameProduct onClick={handleOpenDetailsProduct}>
          {product?.nome}
        </NameProduct>
        <PriceProduct onClick={handleOpenDetailsProduct}>
          {product?.preco}
        </PriceProduct>
        <NumberItemsProduct onClick={handleOpenDetailsProduct}>
          {product?.numero_itens
            ? `Contém ${product?.numero_itens} item(s)`
            : null}
        </NumberItemsProduct>
        <ButtonsContainer>
          <Button
            type="button"
            title="Detalhes do produto"
            onClick={handleOpenDetailsProduct}
          >
            Detalhes
          </Button>
          <Button
            type="button"
            title="Adicionar ao carrinho"
            onClick={() => addProductToCart(product?.id)}
          >
            <img width={26.35} alt="Carrinho" src={iconAddShoppingCart} />
          </Button>
        </ButtonsContainer>
      </DetailsProductContainer>
    </CardProductContainer>
  );
};

export default CardProduct;
