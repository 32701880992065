import React from "react";
import PropTypes from "prop-types";
import { SHOPPING_CART_PRODUCTS } from "../../Api/Produto";
import useAlert from "../Alert/useAlert";

const ShoppingCartContext = React.createContext();

export const ShoppingCartProvider = ({ children }) => {
  const [products, setProducts] = React.useState([]);

  const [productsId, setProductsId] = React.useState(() => {
    const savedProductsId = localStorage.getItem("productsId");
    return savedProductsId ? JSON.parse(savedProductsId) : [];
  });

  const [errorShoppingCart, setErrorShoppingCart] = React.useState(null);
  const [loadingShoppingCart, setLoadingShoppingCart] = React.useState(false);

  const clearShoppingCart = React.useCallback(() => {
    setProductsId([]);
    setProducts([]);
    setErrorShoppingCart(null);
    setLoadingShoppingCart(null);
    localStorage.removeItem("productsId");
  }, [setProductsId, setErrorShoppingCart, setLoadingShoppingCart]);

  const handleShoppingCartProducts = React.useCallback(async () => {
    try {
      setErrorShoppingCart("");
      setLoadingShoppingCart(true);

      const productsIdInString = JSON.stringify(productsId);
      const token = window.localStorage.getItem("token");

      const { url, options } = SHOPPING_CART_PRODUCTS(
        productsIdInString,
        token
      );

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProducts(responseJson.produtos);
      } else {
        if (responseJson.erro) setErrorShoppingCart(responseJson.erro);
        if (responseJson.falha) setErrorShoppingCart(responseJson.falha);
      }
    } catch (err) {
      setErrorShoppingCart("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoadingShoppingCart(false);
    }
  }, [productsId]);

  React.useEffect(() => {
    handleShoppingCartProducts();
  }, [handleShoppingCartProducts]);

  React.useEffect(() => {
    localStorage.setItem("productsId", JSON.stringify(productsId));
  }, [productsId]);

  const { setType, setMessage } = useAlert();

  const addProductToCart = (productId) => {
    setProductsId((prevProducts) => [...prevProducts, productId]);

    setType("default");
    setMessage("Produto adicionado com sucesso.");
  };

  const removeProductFromCart = (productId) => {
    setProductsId((prevProducts) => {
      const index = prevProducts.findIndex((id) => id === productId);
      if (index !== -1) {
        const newProducts = [...prevProducts];
        newProducts.splice(index, 1);
        return newProducts;
      }
      return prevProducts;
    });
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        products,
        productsId,
        errorShoppingCart,
        loadingShoppingCart,
        clearShoppingCart,
        addProductToCart,
        removeProductFromCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

ShoppingCartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ShoppingCartContext };
