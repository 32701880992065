import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Image, Slide, Slides } from "./styles";

import { IMAGE_AD } from "../../Api/Anuncio";

function Carousel({ images, onClick }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const nextSlide = React.useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const prevSlide = React.useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  }, [images.length]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [nextSlide]);

  if (images?.length > 1) {
    return (
      <Container>
        <Slides
          onClick={onClick}
          $totalSlides={images.length}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <Slide key={index}>
              <Image src={IMAGE_AD(image?.nome)} alt={`Slide ${index}`} />
            </Slide>
          ))}
        </Slides>
        <Button direction="prev" onClick={prevSlide}>
          ❮
        </Button>
        <Button direction="next" onClick={nextSlide}>
          ❯
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Slides
          onClick={onClick}
          totalSlides={images.length}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <Slide key={index}>
              <Image src={IMAGE_AD(image?.nome)} alt={`Slide ${index}`} />
            </Slide>
          ))}
        </Slides>
      </Container>
    );
  }
}

Carousel.propTypes = {
  images: PropTypes.array,
};

export default Carousel;
