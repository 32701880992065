import React from "react";
import PropTypes from "prop-types";

const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {
  const [type, setType] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  return (
    <AlertContext.Provider
      value={{
        type,
        setType,
        message,
        setMessage,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AlertContext };
