import React from "react";
import PropTypes from "prop-types";

import { Slider, ToggleSwitchContainer } from "./styles";

function ToggleSwitch({ isActive, onToggle }) {
  return (
    <ToggleSwitchContainer isActive={isActive} onClick={onToggle}>
      <Slider isActive={isActive} />
    </ToggleSwitchContainer>
  );
}

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
};
