import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;

  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  border-radius: 0px 0px 5px 5px;

  > strong {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;
    font-weight: bold;
    font-style: normal;

    text-align: center;

    ${({ theme }) => theme.colors.color_03}
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  height: 100%;

  border-collapse: collapse;

  tr {
    height: 45px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    border-bottom: 1px solid #ddd;

    &:hover {
      background-color: ${({ theme }) => theme.colors.background_02};
    }

    th,
    td {
      flex: 1;

      min-width: 180px;
      width: 100%;
    }

    span {
      color: #ff7f50;
      padding: 10px;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  th {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.25rem;

    color: ${({ theme }) => theme.colors.text_02};
    background-color: ${({ theme }) => theme.colors.color_01};
  }

  td {
    margin: auto 0px;

    text-align: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    > button {
      width: 40%;
      margin: 0px 5px;

      > img {
        margin: auto;
      }
    }
  }

  .openModal {
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 5px;
    border-radius: 8px;

    cursor: pointer;
  }

  .openModal:hover {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.color_01};
  }
`;
