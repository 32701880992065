import React from "react";

import { TablesContainer } from "./styles";

import TableProducts from "./Components/Product/Table";
import TableAds from "./Components/Ad/Table";
import TableCategories from "./Components/Category/Table";

export const Tables = () => {
  const [table, setTable] = React.useState("product");

  return (
    <TablesContainer>
      <div>
        <strong
          className={table === "product" ? "active" : ""}
          onClick={() => {
            setTable("product");
          }}
        >
          Produtos
        </strong>
        <strong
          className={table === "category" ? "active" : ""}
          onClick={() => {
            setTable("category");
          }}
        >
          Categorias
        </strong>
        <strong
          className={table === "ad" ? "active" : ""}
          onClick={() => {
            setTable("ad");
          }}
        >
          Anúncios
        </strong>
      </div>
      {(() => {
        switch (table) {
          case "product":
            return <TableProducts />;
          case "category":
            return <TableCategories />;
          case "ad":
            return <TableAds />;
          default:
            return null;
        }
      })()}
    </TablesContainer>
  );
};
