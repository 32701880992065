import { API_URL } from "..";

const API_URL_CATEGORIA = API_URL + "categorias";

export function CREATE_CATEGORIES(body, token) {
  return {
    url: `${API_URL_CATEGORIA}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function FIND_CATEGORY(categoryId, token) {
  return {
    url: `${API_URL_CATEGORIA}/buscar?categoria_id=${categoryId}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function LIST_CATEGORIES(token, visivel) {
  let query = "";

  if (visivel) {
    query = "?";
    if (visivel) query += `visivel=${visivel}&`;
    query = query.slice(0, -1);
  }
  return {
    url: `${API_URL_CATEGORIA}/listar${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function UPDATE_CATEGORY(categoryId, body, token) {
  return {
    url: `${API_URL_CATEGORIA}/atualizar?categoria_id=${categoryId}`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function DELETE_CATEGORY(categoryId, token) {
  return {
    url: `${API_URL_CATEGORIA}/excluir?categoria_id=${categoryId}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
