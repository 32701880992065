import React from "react";
import PropTypes from "prop-types";

import { SelectContainer } from "./styles";

function Select({ children, value, ...props }) {
  const valueToUse = value === null ? "" : value;

  return (
    <SelectContainer {...props} value={valueToUse}>
      {children}
    </SelectContainer>
  );
}

Select.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
};

export default Select;
