import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import {
  BodySideBar,
  ButtonShoppingCart,
  FooterSideBar,
  HeaderSideBar,
  ItemsShoppingCart,
  Overlay,
  SideBarShoppingCartContainer,
} from "./styles";

import iconWhatsApp from "../../Assets/whatsapp.png";
import iconClose from "../../Assets/close(white).svg";
import iconShoppingCart from "../../Assets/shopping-cart(white).svg";

import Button from "../Button";

import { IMAGE_PRODUCT } from "../../Api/Produto";

import { NUMERO_WHATSAPP } from "../../Helper/link";

import useShoppingCart from "../../Context/ShoppingCart/useShoppingCart";

function SideBarShoppingCart() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);

  const {
    products,
    productsId,
    addProductToCart,
    removeProductFromCart,
    clearShoppingCart,
    errorShoppingCart,
  } = useShoppingCart();

  const handleWhatsAppOrder = () => {
    const phoneNumber = NUMERO_WHATSAPP;

    const greeting = "Olá! Aqui está o meu pedido:\n\n";

    const productList = productsId.reduce((acc, itemId) => {
      const product = products.find((p) => p?.id === itemId);
      if (product) {
        const productCount = productsId.filter((id) => id === itemId).length;
        acc += `${product.nome} - Quantidade: ${productCount} - Valor: ${product.preco}\n`;
      }
      return acc;
    }, "");

    const message = `${greeting}${productList}`;

    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <ButtonShoppingCart>
        <span>{productsId.length}</span>
        <img
          height={40}
          width={45}
          alt="Carrinho"
          src={iconShoppingCart}
          onClick={() => setIsOpen(true)}
        />
      </ButtonShoppingCart>
      {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
      <SideBarShoppingCartContainer isOpen={isOpen}>
        <HeaderSideBar>
          <h1>Carrinho de Compras</h1>
          <button onClick={() => setIsOpen(false)}>
            <img width={40} height={40} alt="Carrinho" src={iconClose} />
          </button>
        </HeaderSideBar>
        <BodySideBar>
          {products.length > 0 && productsId.length > 0 ? (
            products.map((p) => {
              return (
                <ItemsShoppingCart key={p?.id}>
                  <div>
                    <img
                      width={50}
                      height={50}
                      alt="Imagem Produto"
                      src={IMAGE_PRODUCT(p?.imagem_produto)}
                      onClick={() => navigate(`/produto/${p?.id}`)}
                    />
                    <div>
                      <p onClick={() => navigate(`/produto/${p?.id}`)}>
                        {p?.nome}
                      </p>
                      <p onClick={() => navigate(`/produto/${p?.id}`)}>
                        {p?.preco}
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => removeProductFromCart(p?.id)}
                    >
                      -
                    </button>
                    <span>
                      {productsId.filter((id) => id === p?.id).length}
                    </span>
                    <button
                      type="button"
                      onClick={() => addProductToCart(p?.id)}
                    >
                      +
                    </button>
                  </div>
                </ItemsShoppingCart>
              );
            })
          ) : (
            <h2>{errorShoppingCart}</h2>
          )}
        </BodySideBar>
        <FooterSideBar>
          <Button
            type="button"
            className="whatsapp"
            onClick={handleWhatsAppOrder}
          >
            Fazer pedido
            <img height={25} alt="Icone do WhatsApp" src={iconWhatsApp} />
          </Button>
          <Button type="button" onClick={clearShoppingCart}>
            Limpar Carrinho
          </Button>
        </FooterSideBar>
      </SideBarShoppingCartContainer>
    </>
  );
}

SideBarShoppingCart.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default SideBarShoppingCart;
