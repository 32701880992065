import React from "react";
import PropTypes from "prop-types";

import { StyledTable, TableWrapper } from "./styles";
import Loading from "../Loading";

function Table({ children, error, loading }) {
  return (
    <TableWrapper>
      {error ? (
        <strong>{error}</strong>
      ) : loading ? (
        <Loading />
      ) : (
        <StyledTable>{children}</StyledTable>
      )}
    </TableWrapper>
  );
}

Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
