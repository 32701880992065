import React from "react";

import { TableAdsContainer } from "./styles";

import iconUpdate from "../../../../../Assets/update(white).svg";
import iconDelete from "../../../../../Assets/delete(white).svg";

import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";

import Table from "../../../../../Components/Table";
import Button from "../../../../../Components/Button";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";

import {
  FIND_AD,
  LIST_ADS,
  UPDATE_AD,
  DELETE_AD,
} from "../../../../../Api/Anuncio";

import useUser from "../../../../../Context/User/useUser";

const TableAds = () => {
  const [ads, setAds] = React.useState([]);

  const [adData, setAdData] = React.useState(null);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    featchUserToken();
  }, [featchUserToken]);

  const handleListAds = React.useCallback(async () => {
    try {
      setLoading(true);
      setError("");

      const token = localStorage.getItem("token");
      const { url, options } = LIST_ADS(token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setAds(responseJson.anuncios);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha ao listar os anúncios. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    handleListAds();
  }, [handleListAds]);

  const handleFindAd = React.useCallback(async (id) => {
    if (!id) return;

    try {
      const token = window.localStorage.getItem("token");

      const { url, options } = FIND_AD(id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setAdData(responseJson.anuncio);
        return true;
      } else {
        if (responseJson.erro) window.alert(responseJson.erro);
        if (responseJson.falha) window.alert(responseJson.falha);
        return false;
      }
    } catch (error) {
      console.error(error);
      window.alert("Falha ao buscar o anúncio. Tente novamente mais tarde");
    }
  }, []);

  const handleUpdateAd = async (adId, visible) => {
    if (!adId) return;

    try {
      const token = window.localStorage.getItem("token");

      const body = { visivel: JSON.stringify(visible) };

      const { url, options } = UPDATE_AD(adId, body, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        let status = visible === 1 ? 0 : 1;
        setAds((prevAds) =>
          prevAds.map((ad) =>
            ad.id === adId ? { ...ad, visivel: status } : ad
          )
        );
      } else {
        if (responseJson.erro) window.alert(responseJson.erro);
        if (responseJson.falha) window.alert(responseJson.falha);
      }
    } catch (error) {
      console.error(error);
      window.alert("Falha ao atualizar o anúncio. Tente novamente mais tarde");
    }
  };

  const handleDeleteAd = async (id) => {
    if (!id) return;

    try {
      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir este anúncio?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");

      const { url, options } = DELETE_AD(id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        await handleListAds();
      } else {
        if (responseJson.erro) window.alert(responseJson.erro);
        if (responseJson.falha) window.alert(responseJson.falha);
      }
    } catch (error) {
      console.error(error);
      window.alert("Falha ao excluir o anúncio. Tente novamente mais tarde");
    }
  };

  return (
    <TableAdsContainer>
      <div>
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Anúncio
        </Button>
      </div>
      <Table error={error} loading={loading}>
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Data Inicio</th>
            <th>Data Termino</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {ads.map((ad, index) => (
            <tr key={index}>
              <td>{ad?.titulo}</td>
              <td>
                {ad?.data_inicio ? `${ad?.data_inicio_formatada}` : "N/D"}
              </td>
              <td>
                {ad?.data_termino ? `${ad?.data_termino_formatada}` : "N/D"}
              </td>
              <td>
                <ToggleSwitch
                  isActive={ad?.visivel === 1 ? true : false}
                  onToggle={() => {
                    handleUpdateAd(ad?.id, ad?.visivel);
                  }}
                />
              </td>
              <td>
                <Button
                  type="button"
                  title="Atualizar produto"
                  onClick={async () => {
                    const result = await handleFindAd(ad?.id);
                    if (result === true) setIsOpenModalUpdate(true);
                  }}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconUpdate}
                    alt="Atualizar Produto"
                  />
                </Button>
                <Button
                  type="button"
                  title="Excluir produto"
                  onClick={() => handleDeleteAd(ad?.id)}
                >
                  <img
                    width="26"
                    height="26"
                    src={iconDelete}
                    alt="Apagar Produto"
                  />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        handleListAds={handleListAds}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        adData={adData}
        setAdData={setAdData}
        handleFindAd={handleFindAd}
        handleListAds={handleListAds}
      />
    </TableAdsContainer>
  );
};

export default TableAds;
