import React from "react";
import PropTypes from "prop-types";

import { Container, Label, Input, ImagePreview, PreviewImage } from "./styles";

const ImageUpload = ({
  loading,
  label,
  setImage,
  imagePreview,
  setImagePreview,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event);
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      <Label>
        {loading ? "Carregando..." : label}
        <Input
          type="file"
          accept="image/*"
          disabled={loading}
          onChange={handleChange}
        />
      </Label>
      {imagePreview && (
        <ImagePreview>
          <PreviewImage src={imagePreview} alt="Pré-visualização" />
        </ImagePreview>
      )}
    </Container>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  setImage: PropTypes.func,
  imagePreview: PropTypes.string,
  setImagePreview: PropTypes.func,
  onCHange: PropTypes.func,
};
