import styled from "styled-components";

export const ImageSubContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: ${({ theme }) => theme.colors.border_03};

  img {
    width: fit-content;
    height: 180px;
    margin: 0 auto;
  }
`;
