import styled from "styled-components";

export const AlertContainer = styled.div`
  max-width: 100%;
  width: fit-content;

  max-height: 100%;
  height: fit-content;

  padding: 16px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 10px;

  color: white;
  background-color: ${({ color }) => color || "gray"};

  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  p {
    text-align: center;
  }
`;
