export const theme = {
  colors: {
    text_01: "#000000",
    text_02: "#ffffff",

    color_01: "#ff8fae",
    color_02: "#fb6c8e",
    color_03: "#c94e6c",

    // color_01: "lightgray",
    // color_02: "gray",
    // color_03: "darkgray",

    border_01: "#0a0a0a",
    border_02: "#171717",
    border_03: "#262626",

    background_01: "#fafafa",
    background_02: "#ededed",
    background_03: "#e5e5e5",
  },
  font: {
    family: '"Merriweather", serif',
  },
};
