import styled from "styled-components";

export const ShowAlertContainer = styled.div`
  max-width: 200px;
  width: fit-content !important;

  max-height: 100px;
  height: fit-content !important;

  position: fixed;
  right: 20px;
  bottom: 20px;

  display: flex;
  flex-direction: column-reverse;

  align-items: start;

  gap: 10px;

  z-index: 1000;

  overflow: hidden !important;
`;
