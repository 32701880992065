import iconAdvertise from "../../Assets/advertise(black).svg";

import { API_URL } from "..";

const API_URL_AD = API_URL + "anuncios";

export function CREATE_AD(formData, token) {
  return {
    url: `${API_URL_AD}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function FIND_AD(adId, token) {
  return {
    url: `${API_URL_AD}/buscar?anuncio_id=${adId}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function LIST_ADS(token, title) {
  let query = "";

  if (title) {
    query = "?";
    if (title) query += `titulo=${title}&`;
    query = query.slice(0, -1);
  }
  return {
    url: `${API_URL_AD}/listar${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function CATALOG_ADS(token, visivel) {
  let query = "";

  if (visivel) {
    query = "?";
    if (visivel) query += `visivel=${visivel}&`;
    query = query.slice(0, -1);
  }

  return {
    url: `${API_URL_AD}/catalogo${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function UPDATE_AD(adId, body, token) {
  return {
    url: `${API_URL_AD}/atualizar?anuncio_id=${adId}`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function DELETE_AD(adId, token) {
  return {
    url: `${API_URL_AD}/excluir?anuncio_id=${adId}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function IMAGE_AD(image) {
  if (image) {
    return API_URL_AD + "/imagem_anuncio/" + image;
  } else {
    return iconAdvertise;
  }
}
