import styled from "styled-components";

export const ModalImagesContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: center;

  ul {
    width: 100%;
    max-height: 200px;

    padding: 5px 10px;

    display: flex;
    flex-direction: column;

    gap: 10px;
    align-items: center;

    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.background_03};

    overflow-y: auto;

    li {
      width: 100%;
      height: 30px;

      display: flex;
      flex-direction: row;

      gap: 10px;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      text-align: center;
      list-style-type: none;

      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.background_01};

      &:hover {
        background-color: #ff7f7f;
      }
    }
  }

  span {
    color: red;
    text-align: center;
  }
`;

export const ModalImagesSubContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: ${({ theme }) => theme.colors.border_03};

  img {
    width: fit-content;
    height: 180px;
    margin: 0 auto;
  }
`;
