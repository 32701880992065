import React from "react";
import PropTypes from "prop-types";

import { ModalBackground, ModalContainer } from "./styles";

import Button from "../Button";

function Modal({ children, isOpen, onClose, onSubmit }) {
  if (!isOpen) return null;

  const handleClickBackground = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <ModalBackground onClick={handleClickBackground}>
      <ModalContainer onSubmit={handleSubmit}>
        {children}
        <Button type="button" title="Fechar Modal" onClick={onClose}>
          Fechar
        </Button>
      </ModalContainer>
    </ModalBackground>
  );
}

export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
