import styled from "styled-components";

export const CardProductContainer = styled.div`
  max-width: 280px;
  width: 100%;

  max-height: 325px;
  height: 100%;

  padding: 5px;
  margin: 0 auto;

  cursor: pointer;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  gap: 5px;

  border-radius: 0.5rem;

  transition: box-shadow 0.3s ease-in-out;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  &.destaque {
    position: relative;
    border: 2px solid #ffd700;

    > img {
      position: absolute;
      top: -5px;
      left: -5px;
      padding: 2px;
      border-radius: 50%;
      background-color: #ffd700;
    }
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  }
`;

export const MainImageContainer = styled.div`
  max-width: 270px;
  width: 100%;

  max-height: 150px;
  height: 100%;

  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.background_02};

  img {
    max-height: 100%;
    height: 100%;

    margin: 0 auto;
    border-radius: 0.5rem;
  }
`;

export const DetailsProductContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 5px;
`;

export const NameProduct = styled.h1`
  width: calc(100% - 10px);
  max-height: 60px;

  margin: auto 0;

  font-weight: bold;
  font-size: 1.25rem;

  text-align: center;

  line-height: 20px;

  color: ${({ theme }) => theme.colors.color_02};

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
`;

export const PriceProduct = styled.h2`
  width: 100%;

  padding: 2px;

  font-size: 1.125rem;
  font-weight: normal;

  text-align: center;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;

  border-color: ${({ theme }) => theme.colors.background_03};

  background-color: ${({ theme }) => theme.colors.background_02};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NumberItemsProduct = styled.h3`
  width: 100%;

  font-size: 0.85rem;
  font-weight: normal;

  text-align: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row !important;

  align-items: center;

  gap: 5px;

  button:nth-child(1) {
    width: 80% !important;
  }

  button:nth-child(2) {
    width: 20% !important;

    img {
      margin: auto;
    }
  }

  button {
    color: white;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
