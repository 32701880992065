import React from "react";

import useAlert from "../Context/Alert/useAlert";

const useFetch = () => {
  const { setMessage, setType } = useAlert();

  const [loading, setLoading] = React.useState(false);

  const request = React.useCallback(
    async (url, options) => {
      let responseJson;

      try {
        setLoading(true);

        const response = await fetch(url, options);
        responseJson = await response.json();
      } catch (err) {
        responseJson = null;

        setType("fail");
        setMessage("Falha no servidor. Tente novamente mais tarde.");
      } finally {
        setLoading(false);

        console.log(responseJson);
        if (url?.options?.method !== "GET") {
          if (responseJson?.mensagem) {
            setType("success");
            setMessage(responseJson.mensagem);
          } else if (responseJson?.erro) {
            setType("error");
            setMessage(responseJson.erro);
          } else if (responseJson.falha) {
            setType("fail");
            setMessage(responseJson.falha);
          }
        }

        return { response: responseJson };
      }
    },
    [setType, setMessage]
  );

  return {
    loading,
    request,
  };
};

export default useFetch;
